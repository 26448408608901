<template>
  <div>
    <account-role-title role="Store" />
    <!-- <breadcrumbs /> -->
    <span class="title d-block mb-2 ml-1">{{ $t("Orders") }}</span>
    <div class="v-orders">
      <orders-table url="seller-orders-list" order-link="seller-order"></orders-table>
    </div>
  </div>
</template>

<script>
import ordersTable from "@/views/cabinet/order/order-list/OrdersTable";

export default {
  name: "SellerOrderList",
  components:{ordersTable}
}
</script>
